<template>
	<div class="wrapper">
		<vxe-toolbar>
			<template v-slot:buttons>
				<vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
				<vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
				<vxe-button status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加</vxe-button>
			</template>
		</vxe-toolbar>
		<div class="search" v-if="seachStatus">
			<vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
				<vxe-form-item title="角色名称" span="8">
					<el-select v-model="searchData.roleId" placeholder="请选择角色名称" style="width:100%;">
            			<el-option
              				v-for="item in serveRoleList"
              				:key="item.id"
              				:label="item.des"
              				:value="item.id">
            			</el-option>
          			</el-select>
				</vxe-form-item>
				<vxe-form-item title="菜单名称" span="8">
					<vxe-input v-model="searchData.menuName" placeholder="请输入菜单名称" clearable></vxe-input>
				</vxe-form-item>
				<vxe-form-item align="center" span="24">
					<vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
					<vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
				</vxe-form-item>
			</vxe-form>
		</div>
		<div class="table">
			<vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
				<template v-slot:operation="{ row }">
					<vxe-button type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
					<vxe-button type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
				</template>
			</vxe-grid>
		</div>
		<!-- 分页 -->
		<div class="page">
			<vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize" :page-sizes="tablePage.pageSizes"
			 :total="tablePage.totalResult" :layouts="layouts" @page-change="pageChange"></vxe-pager>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapGetters,
		mapActions
	} from "vuex";
	export default {
		data() {
			return {
				loading: false,
				seachStatus: false,
				 serveRoleList: [],
				searchData: {},
				// 分页的参数配置
				tablePage: {
					totalResult: 0,
					currentPage: 1,
					pageSize: 10,
					align: "left",
					pageSizes: [10, 20, 50, 100, 500, 1000],
					perfect: true
				},
				tableColumn: [{
						type: 'seq',
						title: '序号',
						width: 50,
						align: "center"
					},
					{
						field: 'menuName',
						title: '菜单名称',
						showOverflow: true,
						align: "center",
						minWidth: "200"
					},
					{
						field: 'menuUrl',
						title: '页面地址',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						field: 'seq',
						title: '序号',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						field: 'parentMenuName',
						title: '父亲菜单名称',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						field: 'menuRoles',
						title: '角色列表',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						title: '操作',
						width: 120,
						showOverflow: true,
						slots: {
							default: 'operation'
						},
					}
				],
				tableData: [],
			}
		},
		computed: {
			...mapState([
				"layouts"
			]),
		},
		created() {
			this.initView()
		},
		methods: {
			...mapActions([
				"getDictListServe",
				"delDictServe",
				"getRoleListServe",
				"getMenuListServe"
			]),
			initView() {
				this.getLists();
				this.getServeRoleList();
			},
			// 是否显示查询功能
			showSearch(bool) {
				this.seachStatus = bool;
			},
			goPage(type, itemData) {
				if (type == 'viewLine') {
					
				} else {
					this.$router.push({
						name: 'AddDict',
						params: {
							type: type,
							id: type == 'add' ? 'add' : itemData.id
						},
						query: {
							item: type == 'add' ? 'add' : JSON.stringify(itemData)
						}
					})
				}
			},
			getLists() {
				this.loading = true;
				this.getMenuListServe({
					current: this.tablePage.currentPage,
					size: this.tablePage.pageSize,
					param: {
						menuName: this.searchData.menuName,
						roleId: this.searchData.roleId
					}
				}).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.records;
						this.tablePage.totalResult = +res.data.total;
					}
					this.loading = false;
				})
			},
			// 分页功能
			pageChange(item) {
				if (item.type == "size") {
					this.tablePage.currentPage = 1;
				} else {
					this.tablePage.currentPage = item.currentPage;
				}
				this.getLists();
			},
			searchEvent() {
				console.log(this.searchData);
				this.seachStatus = false;
				this.getLists();
			},
			searchReset() {
				this.searchData = {};
				this.getLists();
			},
			remove(id) {
				this.$XModal.confirm("您确定要删除该数据?").then(type => {
					if (type === "confirm") {
						console.log('执行删除操作');
						this.delete(id)
					}
				})
			},
			delete(id) {
				this.delDictServe({
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.$XModal.message({
							message: '删除成功',
							status: "success"
						});
						this.getLists()
					}
					this.loading = false;
				})
			},
			    // 获取服务企业
    		getServeRoleList() {
      			let params = {}
      			this.getRoleListServe(params).then(res => {
        			if (res.code == 200) {
          				this.serveRoleList = res.data;
        			}
      			})
    		},
		}
	}
</script>

<style lang="scss" scoped>
	.wrapper{
	  .datetime{
	    display: flex;
	    align-items: center;
	    span{
	      margin: 0 3px;
	    }
	  }
	}
	.sign i {
		margin-right: 3px;
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}
</style>
